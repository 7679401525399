import '../styles/common.css';

import React, { useEffect, useRef, useState } from 'react';

import { BgWrapper } from '../components/shared/components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../components/shared/section-titles';
import { TitleH1 } from './magazini';
import constants from '../constants/netsurf-fixy';
import { graphql } from 'gatsby';
import meta from '../constants/meta-descriptions';

const NetsurfAssistant = ({ data }) => {
	const [screenSize, setScreenSize] = useState(0);

	let features = [];
	data.wordpressPage.acf.features.split('\n').map((rString, index) => {
		let parts = rString.split('###');

		features.push({
			image: constants.features[index].image,
			title: parts[0],
			text: parts[1]
		});
	});

	useEffect(() => {
		checkScreenSize();
		window.addEventListener('resize', checkScreenSize);
		return () => {
			window.removeEventListener('resize', checkScreenSize);
		};
	}, []);

	const checkScreenSize = () => {
		setScreenSize(window.innerWidth);
	};

	const videoRef = useRef(null);
	const [playButtonClassName, setPlayButtonClassName] = useState('play-button');

	const googlePlayLink =
		'https://play.google.com/store/apps/details?id=com.netsurf.nssa';

	return (
		<>
			<BgWrapper bg={data.bg.childImageSharp.fluid.src} position={'bottom'}>
				<Layout>
					<SEO
						title={meta.assistant.title}
						titleTemplate={`%s`}
						description={meta.assistant.metaDescription}
						image={'fixy-social.png'}
					/>

					<div style={{ paddingTop: '16rem' }}>
						<SectionTitle undertext="Новаторско приложение за напълно оптимизиран процес по оправянето на интернет връзката ти">
							<TitleH1>
								NetSurf Assistant:
								<br />
								за да фиксваш вече и сам проблемите с нета си
							</TitleH1>
							<hr className="center" />
						</SectionTitle>

						<br />
						<br />
						<br />
						<br />
						<br />

						<div className="row d-flex justify-content-center my-5">
							<div className="col-12 col-lg-11 col-xl-9 row d-flex justify-content-center">
								{features.map(({ image, title, text }, index) => (
									<div
										className="col-12 col-sm-6 col-xl-4 text-center px-md-5 my-3 my-sm-5"
										key={index}
									>
										<ScrollAnimation
											animateIn="fadeIn"
											animateOnce={true}
										>
											<div
												className="d-flex justify-content-center align-items-center"
												style={{
													minHeight: '100px'
												}}
											>
												<img
													src={image}
													className="img-fluid mb-3"
													style={{ maxHeight: '100px' }}
													alt={title}
												/>
											</div>
											<div className="px-sm-4">
												<h3>{title}</h3>
												<p>{text}</p>
											</div>
										</ScrollAnimation>
									</div>
								))}
							</div>
						</div>
					</div>
				</Layout>
			</BgWrapper>
		</>
	);
};

export const query = graphql`
	query NetSurfAssistant {
		wordpressPage(slug: { eq: "netsurf-fixy" }) {
			acf {
				features
			}
		}
		bg: file(relativePath: { eq: "netsurfplay/bg.png" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					src
				}
			}
		}
		videoBg: file(relativePath: { eq: "netsurfplay/video-bg.png" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					src
				}
			}
		}
	}
`;

export default NetsurfAssistant;

